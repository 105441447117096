<template>
  <button
    :id="name"
    :type="buttonType"
    :class="`${roundedKlass} ${customKlass} py-4 px-4 text-white shadow-md text-base bg-${bgColor} w-full font-medium ${disabledBgColor}`"
    v-bind="buttonProps"
    :disabled="isDisabled"
    @click="$emit('click')"
  >
    <slot></slot>
    <span>
      {{ text || name }}
    </span>
  </button>
</template>
<script setup lang="ts">
  import { ref, watchEffect, computed, type ButtonHTMLAttributes } from 'vue';

  const props = defineProps<{
    name?: string;
    text?: string;
    hasLoader?: boolean;
    disable?: boolean;
    buttonProps?: ButtonHTMLAttributes;
    disabledColor?: string;
    color?: string;
    flat?: boolean
    customKlass?: string;
    buttonType?: 'submit' | 'button' | 'reset';
  }>();

  defineEmits<{
    (e: 'click'): void
  }>();

  const isDisabled = ref<boolean>();
  const disabledBgColor = computed(() => props.disabledColor ? `disabled:bg-${props.disabledColor} disabled:opacity-5 disabled:cursor-not-allowed`: 'disabled:bg-toggle-off disabled:opacity-25 disabled:cursor-not-allowed');
  const bgColor = props.color || 'primary';
  const roundedKlass = props.flat ? 'rounded-none' : 'rounded-lg';
  const buttonType = computed(() => props.buttonType || 'submit');

  watchEffect(() => {
    isDisabled.value = props.disable;
  });

</script>
